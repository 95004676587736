import { Token } from "../ioc/Token";

/**
 * 日志级别
 */
export type LogLevel = "debug" | "info" | "warn" | "error";

/**
 * 日志服务注册令牌
 */
export const LogServiceToken = new Token<LogService>("LogService");

/**
 * 日志服务
 */

export interface LogService {
  /**
   * 记录异常
   * @param message 消息
   * @param optionalParams 参数
   */
  error(message?: any, ...optionalParams: any[]): void;

  /**
   * 记录警告
   * @param message 消息
   * @param optionalParams 参数
   */
  warn(message?: any, ...optionalParams: any[]): void;

  /**
   * 记录信息
   * @param message 消息
   * @param optionalParams 参数
   */
  info(message?: any, ...optionalParams: any[]): void;

  /**
   * 记录调试信息
   * @param message 消息
   * @param optionalParams 参数
   */
  debug(message?: any, ...optionalParams: any[]): void;
}
