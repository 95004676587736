import { Service } from "@/services/ioc/Service";
import { settings } from "@/settings";
import { Observable, of } from "rxjs";
import { App } from "vue";
import {
  NavigationService,
  NavigationServiceToken,
} from "../NavigationService";
export class MapResult {
  constructor(public mapped: boolean, public mappedUrl: string) {}
}

@Service(NavigationServiceToken)
export class UniNavigationService implements NavigationService {
  private returnUrls: string[] = [];

  config = {
    schemas: ["http://l.onrecord.cn/", "onrecord://"],
    maps: [
      {
        path: "member",
        mapped: "/packages/member/pages/contact/contact",
      },
    ],
  };

  constructor() {
    this.config = {
      ...this.config,
      ...settings.navigation,
    };
  }
  checkArguments() {
    console.log("Shortcut-plus.runtime.launcher: " + plus.runtime.launcher);
    try {
      console.log("Shortcut-plus.runtime.arguments: " + plus.runtime.arguments);
    } catch (e) {
      console.log("Shortcut-exception: " + e);
    }
  }

  init(): void {
    // #ifdef APP-PLUS
    this.checkArguments();
    plus.globalEvent.addEventListener("newintent", (e: any) => {
      this.checkArguments(); // 检测启动参数
    });
    // #endif
    uni.onTabBarMidButtonTap(() => {
      uni.navigateTo({
        url: "/pages/publish/publish",
        animationType: "none",
      });
    });

    // #ifdef APP-PLUS

    // #endif
  }

  mapUrl(url: string): string {
    let mapResult = this.mapLink(url);
    return mapResult.mappedUrl;
  }

  private mapLink(url: string) {
    let schemaIndex = this.config.schemas.findIndex((s: string) =>
      url.startsWith(s)
    );
    if (schemaIndex == -1) {
      return new MapResult(false, url);
    }
    let path = url.substring(this.config.schemas[schemaIndex].length);
    return new MapResult(true, `/${path}`);
  }

  navigateTo(url: string, needMap: boolean = false): void {
    if (needMap) {
      let mapResult = this.mapLink(url);
      url = mapResult.mappedUrl;
    }
    if (url.indexOf("://") > -1) {
      plus.runtime.openURL(url);
    } else {
      uni.navigateTo({ url });
    }
  }

  navigateBack(): void {
    uni.navigateBack();
  }

  redirectTo(url: string, needMap: boolean = false): void {
    if (needMap) {
      let mapResult = this.mapLink(url);
      url = mapResult.mappedUrl;
    }
    if (url.indexOf("://") > -1) {
      plus.runtime.openURL(url);
    } else {
      uni.redirectTo({ url });
    }
  }

  goHome(): void {
    uni.switchTab({
      url: "/pages/home/home",
      fail: (e) => {
        console.info("出错了" + e);
      },
    });
  }

  goLogin(): void {
    uni.redirectTo({ url: "/pages/login/login-by-token/login-by-token" });
  }

  pushReturnUrl(url: string, needMap: boolean = false): void {
    if (needMap) {
      let mapResult = this.mapLink(url);
      url = mapResult.mappedUrl;
    }
    this.returnUrls.push(url);
  }

  hasReturnUrl(): boolean {
    return this.returnUrls.length > 0;
  }

  popReturnUrl(): void {
    if (this.returnUrls.length > 0) {
      let url = this.returnUrls.pop();
      uni.navigateTo({ url: url! });
    }
  }
}
