import { Service } from "@/services/ioc/Service";
import { StoreServiceToken, StoreService } from "../StoreService";

/**
 * 本地服务实现
 */
@Service(StoreServiceToken)
export class UniStoreService implements StoreService {
  get(key: string): string | null {
    return uni.getStorageSync(key);
  }
  set(key: string, value: string): void {
    uni.setStorageSync(key, value);
  }
  remove(key: string): void {
    uni.removeStorageSync(key);
  }
  clear(): void {
    uni.clearStorageSync();
  }
}
