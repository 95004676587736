import { BehaviorSubject, Observable } from "rxjs";
import { ServiceInit } from "../ioc/ServiceInit";
import { Token } from "../ioc/Token";
import { ServiceStart } from "../launch/ServiceStart";

/**
 * 设备服务注册令牌
 */
export const DeviceServiceToken = new Token<DeviceService>("DeviceService");

export interface NetworkStatus {
  isConnected: boolean;
  networkType: string;
}
// barCode	一维码
// qrCode	二维码
// datamatrix	Data Matrix 码
// pdf417
export interface ScanResult {
  scanType: string;
  result: string;
}

export class DeviceLocation {
  constructor(
    public hasLocation: boolean,
    public longitude?: number,
    public latitude?: number,
    public cityCode?: string,
    public province?: string,
    public city?: string,
    public district?: string,
    public poi?: string
  ) {}
}

/**
 * 设备服务
 */
export interface DeviceService extends ServiceStart, ServiceInit {
  /**
   * 获取设备标识
   */
  getDeviceId(): string;

  /**
   * 网络状态变化事件
   */
  onNetworkStatusChanged: BehaviorSubject<NetworkStatus>;

  /**
   * 获取设备位置
   */
  getLocation(): Observable<DeviceLocation>;

  /**
   * 扫描二维码
   */
  scanCode(option?: any): Observable<ScanResult>;
}
