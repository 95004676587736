export function Api(
  path: string,
  method: string = "post",
  cacheKey?: string,
  cacheTime?: number
) {
  return function (target: Function) {
    if (!target.prototype.$Meta) {
      target.prototype.$Meta = {
        path,
        method,
        cacheKey,
        cacheTime,
      };
    }
  };
}
