import { ApiResponse } from "./ApiResponse";

export class ApiError extends Error {
  private _request: any;
  get regquest() {
    return this._request;
  }

  private _response: ApiResponse;

  get response() {
    return this._response;
  }

  constructor(
    message: string,
    request: any,
    response: ApiResponse,
    options?: ErrorOptions
  ) {
    super(message, options);
    this._request = request;
    this._response = response;
  }
}
