import { Observable } from "rxjs";
import { ServiceInit } from "../ioc/ServiceInit";
import { Token } from "../ioc/Token";

import { ApiResponse } from "./ApiResponse";

export const ApiServiceToken = new Token<ApiService>("ApiService");

export interface ApiService extends ServiceInit {
  callService<T extends ApiResponse>(
    request: any,
    options?: {
      timeout?: number;
      url?: string;
    }
  ): Observable<T>;

  callServiceAndShowError<T extends ApiResponse>(
    request: any,
    options?: {
      timeout?: number;
      url?: string;
    }
  ): Observable<T>;
}
