import {
  TokenService,
  TokenServiceToken,
} from "@/services/account/TokenService";
// #ifdef APP-PLUS
import {
  DatabaseService,
  DatabaseServiceToken,
} from "@/services/database/DatabaseService";
// #endif
import {
  DeviceService,
  DeviceServiceToken,
} from "@/services/device/DeviceService";
import { Inject } from "@/services/ioc/Inject";
import { Service } from "@/services/ioc/Service";
import {
  NavigationService,
  NavigationServiceToken,
} from "@/services/navigation/NavigationService";

import { LaunchService, LaunchServiceToken } from "../LaunchService";

@Service(LaunchServiceToken)
export class UniLaunchService extends LaunchService {
  @Inject(DeviceServiceToken)
  deviceService!: DeviceService;
  // #ifdef APP-PLUS
  @Inject(DatabaseServiceToken)
  databaseService!: DatabaseService;
  // #endif
  @Inject(TokenServiceToken)
  tokenService!: TokenService;

  public prepare(): void {
    this.registerService(this.deviceService);

    this.registerService(this.tokenService);
    // #ifdef APP-PLUS
    this.registerService(this.databaseService);
    // #endif
  }
}
