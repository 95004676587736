import { Service } from "@/services/ioc/Service";
import { settings } from "@/settings";
import { LogService, LogServiceToken } from "../LogService";

/**
 * 控制台日志服务
 */
@Service(LogServiceToken)
export class ConsoleLogService implements LogService {
  /**
   * 日志缺省配置
   */
  config = {
    /**
     * 日志级别
     */
    level: "info",
  };

  private logLevel = 0;

  //#region 构造方法
  constructor() {
    this.config = {
      ...this.config,
      ...settings.log,
    };

    switch (this.config.level) {
      case "error":
        this.logLevel = 0;
        break;
      case "warn":
        this.logLevel = 1;
        break;
      case "info":
        this.logLevel = 2;
        break;
      case "debug":
        this.logLevel = 3;
        break;
      default:
        this.logLevel = 2;
        break;
    }
    // this.debug(`日志服务配置信息为:${JSON.stringify(this.config)}`);
  }
  //#endregion

  /**
   * 记录异常
   * @param message 消息
   * @param optionalParams 参数
   */
  error(message?: any, ...optionalParams: any[]): void {
    console && console.error(message, optionalParams);
  }

  /**
   * 记录警告
   * @param message 消息
   * @param optionalParams 参数
   */
  warn(message?: any, ...optionalParams: any[]): void {
    if (this.logLevel > 0) {
      console && console.warn(message, optionalParams);
    }
  }

  /**
   * 记录信息
   * @param message 消息
   * @param optionalParams 参数
   */
  info(message?: any, ...optionalParams: any[]): void {
    if (this.logLevel > 1) {
      // tslint:disable-next-line: no-console
      console && console.info(message, optionalParams);
    }
  }

  /**
   * 记录调试信息
   * @param message 消息
   * @param optionalParams 参数
   */
  debug(message?: any, ...optionalParams: any[]): void {
    if (this.logLevel > 2) {
      // tslint:disable-next-line: no-console
      console && console.info(message, optionalParams);
    }
  }
}
