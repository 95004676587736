import { Observable, throwError } from "rxjs";
import { Interceptor } from "./Interceptor";
import { GetNextInterceptorDelegator } from "./GetNextInterceptorDelegator";
import { InterceptorRequest } from "./InterceptorRequest";
import { InterceptorResponse } from "./InterceptorResponse";

export class InterceptorChain {
  private interceptors: Interceptor[] = [];

  constructor(interceptors: Interceptor[]) {
    if (interceptors) {
      interceptors.forEach((i) => this.interceptors.push(i));
    }
    this.interceptors.forEach((i) => {
      i.init(this);
    });
  }

  intercept(request: InterceptorRequest): Observable<InterceptorResponse> {
    const interceptors = this.interceptors;
    if (interceptors.length === 0) {
      return throwError(() => new Error("HTTP拦截调用链没有任何拦截器"));
    }

    let i = 0;
    return interceptors[0].intercept(request, {
      getNextInterceptor() {
        i++;
        if (i < interceptors.length) {
          return interceptors[i];
        } else {
          return null;
        }
      },
    } as GetNextInterceptorDelegator);
  }
}
