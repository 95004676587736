import { ServiceInit } from "../ioc/ServiceInit";
import { Token } from "../ioc/Token";
import { ServiceStart } from "../launch/ServiceStart";

export const NavigationServiceToken = new Token<NavigationService>(
  "NavigationService"
);

/**
 * 导航服务
 */
export interface NavigationService extends ServiceInit {
  mapUrl(url: string): string;

  /**
   * 跳转到指定页面
   * @param url
   */
  navigateTo(url: string, needMap?: boolean): void;

  /**
   * 回退页面
   */
  navigateBack(): void;

  /**
   * 重定向到指定页面
   * @param url
   */
  redirectTo(url: string, needMap?: boolean): void;

  /**
   * 跳转到首页
   */
  goHome(): void;

  /**
   * 跳转到登录页
   */
  goLogin(): void;

  /**
   * 回跳页面入栈
   * @param url
   */
  pushReturnUrl(url: string, needMap?: boolean): void;

  /**
   * 是否有回跳页面
   */
  hasReturnUrl(): boolean;

  /**
   * 回跳页面出栈
   */
  popReturnUrl(): void;
}
