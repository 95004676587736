import { Token } from "../ioc/Token";

/**
 * 存贮服务注册令牌
 */
export const StoreServiceToken = new Token<StoreService>("StoreService");
/**
 * 存贮服务
 */
export interface StoreService {
  /**
   * 获取数据
   * @param key 键
   */
  get(key: string): string | null;

  /**
   * 设置数据
   * @param key 键
   * @param value 值
   */
  set(key: string, value: string): void;

  /**
   * 移除数据
   * @param key 键
   */
  remove(key: string): void;

  /**
   * 清除所有数据
   */
  clear(): void;
}
