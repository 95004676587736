import "reflect-metadata";

import { createSSRApp } from "vue";
import App from "./App.vue";
import ContainerPlugin from "./services/ioc/ContainerPlugin";
import ModalMixins from "./uni_modules/za-ui/components/za-modal/modal-mixins";

export function createApp() {
  const app = createSSRApp(App);
  app.mixin(ModalMixins);
  app.use(ContainerPlugin);
  return {
    app,
  };
}
