import {
  AccountService,
  AccountServiceToken,
} from "@/services/account/AccountService";
import {
  TokenService,
  TokenServiceToken,
} from "@/services/account/TokenService";
import {
  type DeviceService,
  DeviceServiceToken,
} from "@/services/device/DeviceService";
import { Inject } from "@/services/ioc/Inject";
import { Service } from "@/services/ioc/Service";
import AssertTool from "@/services/tools/AssertTool";
import { settings } from "@/settings";
import { Subject } from "rxjs";
import Stomp from "stompjs";

import { LogServiceToken, type LogService } from "../../logging/LogService";
import { StompService, StompServiceToken } from "../StompService";

//#region 防止stomp调用browser计时器对象
Stomp.setInterval = function (interval, f) {
  let timer: any = setInterval(f, interval);
  return timer;
};

Stomp.clearInterval = function (id) {
  return clearInterval(id);
};
//#endregion

/**
 * 消息服务
 */
@Service(StompServiceToken)
export class UniStompService implements StompService {
  /**
   * 断线重连计数
   */
  private reconnectTimes = 0;

  /**
   * 是否用户主动关闭连接
   */
  private isUserClosing = false;

  /**
   * stomp客户端
   */
  private stompClient: Stomp.Client | null = null;

  /**
   * 是否已打开
   */
  private isOpen = false;

  /**
   * 消息通知
   */
  private onMessageReceived$: { [topic: string]: Subject<Stomp.Message> } = {};

  /**
   * 待发送消息
   */
  private messageQueue: { topic: string; headers?: {}; body?: string }[] = [];

  /**
   * 连接事件
   */
  private onConnected$: Subject<boolean> = new Subject();

  @Inject(LogServiceToken)
  private logService!: LogService;
  @Inject(TokenServiceToken)
  private tokenService!: TokenService;
  @Inject(AccountServiceToken)
  private accountService!: AccountService;
  @Inject(DeviceServiceToken)
  private deviceService!: DeviceService;

  /**
   * 配置
   */
  config = {
    /**
     * 服务器地址
     */
    server: "wss://bcl-api.onrecord.cn/user/chat/ws",
    /**
     * 重连间隔
     */
    reconnectPeriod: 3000,
    /**
     * 最大重连次数
     */
    reconnectMaxTimes: 10,

    headers: {},
    /**
     * 订阅的主题
     */
    topics: ["/user/{userKey}/msg/receive"],
  };
  /**
   * 构造方法
   */
  constructor() {
    this.config = {
      ...this.config,
      ...settings.stomp,
    };
    this.config.topics.map((t) => {
      let subject = new Subject<Stomp.Message>();
      this.onMessageReceived$[t] = subject;
    });
  }
  get priority(): number {
    return 30;
  }

  init(): void {
    this.accountService.onAccountChanged.subscribe(() => {
      this.disconnect();
      this.tryConnect();
    });
    uni.onAppShow(()=>{
      if(this.deviceService.onNetworkStatusChanged.value.isConnected){
        this.tryConnect();
      }
    });
    // this.tokenService.onTokenChanged.subscribe(() => {
    //   this.tryConnect();
    // });
    this.deviceService.onNetworkStatusChanged.subscribe((status) => {
      if (status.isConnected) {
        this.tryConnect();
      }
    });
  }
  /**
   * 已连接事件
   */
  public onConnected(): Subject<boolean> {
    return this.onConnected$;
  }

  /**
   * 关闭事件
   */
  private onDisConnected$: Subject<boolean> = new Subject();

  /**
   * 异常事件
   */
  private onError$: Subject<any> = new Subject();

  /**
   * 已关闭事件
   */
  public onDisConnected(): Subject<boolean> {
    return this.onDisConnected$;
  }

  /**
   * 连接异常事件
   */
  public onError(): Subject<any> {
    return this.onError$;
  }

  /**
   * 消息订阅
   * @param topic 主题
   */
  public onMessageReceived(topic: string): Subject<Stomp.Message> {
    let subject = this.onMessageReceived$[topic];
    AssertTool.notNull(subject, "主题未订阅！");
    return subject;
  }

  public tryConnect() {
    let currentToken = this.tokenService.currentToken;
    let currentAccount = this.accountService.currentAccount;

    if (
      currentToken.getRawToken() &&
      currentAccount.getUserKey() &&
      !this.isOpen
    ) {
      this.connect();
    }
  }

  //#region 连接

  //region 心跳
  private beatTimer?: any;
  private startBeat() {
    this.beatTimer = setTimeout(() => {
      this.sendMessage("/app/hello", "hello");
    }, 60000);
  }

  private resetBeat() {
    if (this.beatTimer) {
      clearTimeout(this.beatTimer);
    }
    this.startBeat();
  }
  //endregion

  //#region 创建websocket
  /**
   * 创建websocket
   */
  private createWebSocket() {
    let token = this.tokenService.currentToken;
    let userKey = this.accountService.currentAccount.getUserKey();
    let socketTask = uni.connectSocket({
      url: `${this.config.server}?token=${token.getRawToken()}&user=${userKey}`,
      success: () => {},
    });

    var ws: any = {
      send: (frame: any) => {
        socketTask.send({ data: frame });
      },
      close: (frame: any) => {
        socketTask.close(frame);
      },
    };

    socketTask.onOpen((frame) => {
      if (ws.onopen) {
        ws.onopen(frame);
      }
    });

    socketTask.onMessage(function (frame) {
      if (ws.onmessage) {
        ws.onmessage(frame);
      }
    });

    socketTask.onClose((frame) => {
      if (ws.onclose) {
        ws.onclose(frame);
      }
    });

    socketTask.onError((frame) => {
      if (ws.onclose) {
        ws.onclose(frame);
      }
    });

    return ws;
  }
  //#endregion

  //#region 建立连接

  /**
   * 建立连接
   * @param callBack
   * @param fail
   */
  private connectInternal() {
    if (this.stompClient) {
      this.stompClient?.disconnect(() => {});
    }
    let ws = this.createWebSocket();
    ws.onclose = () => {
      if (!this.isUserClosing) {
        this.connectInternal();
      }
    };
    this.stompClient = Stomp.over(ws);
    this.stompClient!.connect(
      this.config.headers,
      (frame: any) => {
        this.isOpen = true;
        this.reconnectTimes = 0;
        let userKey = this.accountService.currentAccount.getUserKey();
        //重新订阅消息
        this.config.topics.map((t) => {
          let topic = t.replace("{userKey}", userKey);
          let subject = this.onMessageReceived$[t];
          this.stompClient!.subscribe(topic, (msg) => {
            subject.next(msg);
          });
        });
        //发送缓存的消息
        this.messageQueue.map((m) => {
          this.sendMessage(m.topic, m.body);
        });
        this.messageQueue = [];
        this.onConnected$.next(true);
      },
      (error: any) => {
        this.isOpen = false;
        setTimeout(() => {
          //如果正在关闭，则结束
          if (this.isUserClosing) {
            this.onDisConnected$.next(true);
            return;
          }
          //如果最大次数到达则结束
          this.reconnectTimes += 1;
          if (this.reconnectTimes >= this.config.reconnectMaxTimes) {
            this.onError$.next(error);
            return;
          }
          //否则重试
          this.connectInternal();
        }, this.config.reconnectPeriod);
      }
    );
  }

  /**
   * 连接服务器
   */
  public connect() {
    this.isUserClosing = false;
    this.reconnectTimes = 0;

    this.startBeat();

    if (this.isOpen) {
      this.disconnectInternal(() => {
        this.connectInternal();
      });
    } else {
      this.connectInternal();
    }
  }
  //#endregion

  //#region 关闭连接
  private disconnectInternal(callBack?: () => void) {
    this.stompClient!.disconnect(() => {
      this.onDisConnected$.next(true);
      if (callBack) {
        callBack();
      }
    });
  }

  /**
   * 关闭连接
   */
  public disconnect() {
    this.isUserClosing = true;
    if (this.isOpen) {
      this.disconnectInternal();
    }
  }

  //#endregion

  //#region 发送消息

  /**
   * 发送消息
   * @param topic 主题
   * @param headers 消息头
   * @param body 消息内容
   */
  public sendMessage(topic: string, body?: string) {
    if (this.isOpen) {
      this.resetBeat();
      this.stompClient!.send(topic, {}, body);
    } else {
      this.messageQueue.push({ topic, body });
    }
  }

  //#endregion
}
