import Container from "@/services/ioc/Container";
import { LogServiceToken } from "@/services/logging/LogService";
import { catchError, map, Observable, throwError } from "rxjs";
import { GetNextInterceptorDelegator } from "../../http/GetNextInterceptorDelegator";
import { Interceptor } from "../../http/Interceptor";
import { InterceptorChain } from "../../http/InterceptorChain";
import { InterceptorRequest } from "../../http/InterceptorRequest";
import { InterceptorResponse } from "../../http/InterceptorResponse";

export class LogInterceptor implements Interceptor {
  init(interceptorChain: InterceptorChain): void {}
  intercept(
    request: InterceptorRequest,
    getNextInterceptorDelegator: GetNextInterceptorDelegator
  ): Observable<InterceptorResponse> {
    const nextHandler = getNextInterceptorDelegator.getNextInterceptor();
    const startTime = new Date();

    return nextHandler.intercept(request, getNextInterceptorDelegator).pipe(
      map((response) => {
        const logService = Container.get(LogServiceToken);
        const ticks = new Date().valueOf() - startTime.valueOf();
        logService.debug(
          `API调用成功：请求契约'${
            request.data!.constructor.name
          }'，耗时${ticks}毫秒：`
        );
        logService.debug(`API请求：${JSON.stringify(request.data)}`);
        logService.debug(`API响应：${JSON.stringify(response)}`);
        return response;
      }),
      catchError((err) => {
        const logService = Container.get(LogServiceToken);
        const ticks = new Date().valueOf() - startTime.valueOf();
        logService.debug(
          `API调用成功：请求契约'${
            request.data!.constructor.name
          }'，耗时${ticks}毫秒：`
        );
        logService.debug(`API请求：${JSON.stringify(request.data)}`);
        logService.debug(`API响应：${JSON.stringify(err)}`);
        throw err;
      })
    );
  }
}
