import AssertTool from "../tools/AssertTool";
import { ContainerInterface } from "./ContainerInterface";
import { ContainerMeta } from "./ContainerMeta";
import { InjectMeta } from "./InjectMeta";
import { ServiceMeta } from "./ServiceMeta";
import { Token } from "./Token";

export class ContainerInstance implements ContainerInterface {
  private serviceMetas = new Map<Token<any>, ServiceMeta>();
  private injectMetas = new Map<any, InjectMeta[]>();

  private retriveService(serviceMeta: ServiceMeta) {
    AssertTool.notNull(serviceMeta, "服务元数据不存在，无法获取服务！");
    if (serviceMeta.value) {
      return serviceMeta.value;
    }

    let value = new serviceMeta.target();
    let seriveInjectMetas = this.injectMetas.get(serviceMeta.target);
    if (seriveInjectMetas) {
      seriveInjectMetas.forEach((injectMeta) => {
        value[injectMeta.property] = this.get(injectMeta.token);
      });
    }
    if (value.init && typeof value.init === "function") {
      value.init();
    }
    serviceMeta.value = value;
    return value;
  }

  has<T = unknown>(token: Token<T>): boolean {
    return this.serviceMetas.has(token) && this.serviceMetas.get(token)!.value;
  }

  get<T = unknown>(token: Token<T>): T {
    let serviceMeta = this.serviceMetas.get(token);
    if (!serviceMeta) {
      serviceMeta = ContainerMeta.getServiceMeta(token);
    }
    if (!serviceMeta) {
      return null as T;
    }
    let serviceInjectMetas = ContainerMeta.getServiceInjectMeta(
      serviceMeta?.target
    );
    if (serviceInjectMetas) {
      this.injectMetas.set(serviceMeta?.target, serviceInjectMetas);
    }
    return this.retriveService(serviceMeta!);
  }

  bind<T = unknown>(token: Token<T>, clazz: any) {
    let serviceMeta = new ServiceMeta();
    serviceMeta.target = clazz;
    this.serviceMetas.set(token, serviceMeta);
  }
}
