import { Observable, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";

import { GetNextInterceptorDelegator } from "../../http/GetNextInterceptorDelegator";
import { Interceptor } from "../../http/Interceptor";
import { InterceptorChain } from "../../http/InterceptorChain";
import { InterceptorRequest } from "../../http/InterceptorRequest";
import { InterceptorResponse } from "../../http/InterceptorResponse";

import { NavigationServiceToken } from "@/services/navigation/NavigationService";
import { TokenServiceToken } from "@/services/account/TokenService";
import Container from "@/services/ioc/Container";

export const API_ROUTE_CODE = "x-arch-route-code";
export const API_ROUTE_MESSAGE = "x-arch-route-message";

export class TokenInterceptor implements Interceptor {
  init(_interceptorChain: InterceptorChain): void {}

  intercept(
    req: InterceptorRequest,
    getNextInterceptorDelegator: GetNextInterceptorDelegator
  ): Observable<InterceptorResponse> {
    let navigateService = Container.get(NavigationServiceToken);
    let tokenService = Container.get(TokenServiceToken);

    let token = tokenService.currentToken;
    if (token.hasToken()) {
      req.headers["Authorization"] = `Bearer ${token.getRawToken()}`;
    }

    const nextHandler = getNextInterceptorDelegator.getNextInterceptor();

    return nextHandler.intercept(req, getNextInterceptorDelegator).pipe(
      mergeMap((response: InterceptorResponse) => {
        if (response.statusCode == 401) {
          tokenService.logout();
          navigateService.goLogin();
          return of(response);
        }
        let routeCode = response.headers[API_ROUTE_CODE];
        if (routeCode) {
          if (routeCode == "TOKEN_NOT_EXIST" || routeCode == "TOKEN_INVALID") {
            navigateService.goLogin();
            return of(response);
          }
          if (
            routeCode == "REFRESH_TOKEN" &&
            !req.url.endsWith(`/bcl-member/user-login/refreshOauthToken`)
          ) {
            return tokenService.refreshToken().pipe(map((r) => response));
          }
        }
        return of(response);
      })
    );
  }
}
