import { Token } from "@/services/ioc/Token";
import { Observable } from "rxjs";
import { MessageEntity } from "./entity/MessageEntity";
import { SessionEntity } from "./entity/SessionEntity";
import { UserEntity } from "./entity/UserEntity";

export const ChatDataAccessToken = new Token<ChatDataAccess>("ChatDataAccess");

export interface ChatDataAccess {
  //#region 事务
  beginTransaction(): Observable<boolean>;

  commitTransaction(): Observable<boolean>;

  rollbackTransaction(): Observable<boolean>;
  //#endregion

  //#region 用户
  getUser(userKey: string): Observable<UserEntity | undefined>;

  saveUser(user: UserEntity): Observable<boolean>;
  //#endregion

  //#region 会话
  querySession(userKey: string): Observable<SessionEntity[]>;

  getSession(
    userKey: string,
    sessionKey: string
  ): Observable<SessionEntity | undefined>;

  saveSession(session: SessionEntity): Observable<boolean>;

  deleteSession(userKey: string, sessionKey: string): Observable<boolean>;

  emptySession(userKey: string, sessionKey: string): Observable<boolean>;

  markSessionRead(userKey: string, sessionKey: string): Observable<boolean>;
  //#endregion

  //#region 消息处理
  queryMessage(
    userKey: string,
    sessionKey: string,
    maxRecord: number,
    sentTime: number | undefined
  ): Observable<MessageEntity[]>;

  querySessionUnreadMessage(
    userKey: string,
    sessionKey: string
  ): Observable<MessageEntity[]>;

  getMessage(
    userKey: string,
    sessionKey: string,
    messageKey: string
  ): Observable<MessageEntity | undefined>;

  saveMessage(message: MessageEntity): Observable<boolean>;
  //#endregion

  //#region 汇总查询
  getUnreadMessageCount(userKey: string): Observable<number>;
  //#endregion
}
