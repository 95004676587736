import { Api } from '@/services/api/Api';
/**
 * 刷新统一登录令牌请求
 */
@Api('/bcl-member/user-login/refreshOauthToken')
export class RefreshOauthTokenRequest {
	/**
	 * 统一登录令牌
	 */
	public oauthToken!: string;
	/**
	 * 设备特征
	 */
	public deviceFeature?: string;
}
