import { Service } from "@/services/ioc/Service";
import { Locker } from "@/services/schedule/Locker";
import { Observable, of } from "rxjs";
import { ChatDataAccess, ChatDataAccessToken } from "../../ChatDataAccess";
import { MessageEntity } from "../../entity/MessageEntity";
import { SessionEntity } from "../../entity/SessionEntity";
import { UserEntity } from "../../entity/UserEntity";

@Service(ChatDataAccessToken)
export class H5ChatDataAccess implements ChatDataAccess {
  //#region 私有字段
  private locker = new Locker();
  private users: Map<string, UserEntity> = new Map();
  private userSessions: Map<string, SessionEntity[]> = new Map();
  private userMessages: Map<string, MessageEntity[]> = new Map();
  //#endregion

  //#region 私有方法
  takeLastItems(items: any[], n: number) {
    if (items && items.length > n) {
      let results = items.slice(items.length - n - 1);
      return results;
    } else {
      return [...items];
    }
  }

  private sortSessions(sessions: SessionEntity[]) {
    return sessions.sort((s1, s2) => {
      if (s1.fixTop !== s2.fixTop) {
        if (s1.fixTop) {
          return 1;
        } else {
          return -1;
        }
      } else {
        return s2.activeTime - s1.activeTime;
      }
    });
  }

  private sortMessages(messages: MessageEntity[]) {
    return messages.sort((m1, m2) => {
      return m1.sentTime - m2.sentTime;
    });
  }
  //#endregion

  //#region 事务
  beginTransaction(): Observable<boolean> {
    return this.locker.lock();
  }
  commitTransaction(): Observable<boolean> {
    this.locker.unlock();
    return of(true);
  }
  rollbackTransaction(): Observable<boolean> {
    this.locker.unlock();
    return of(false);
  }
  //#endregion

  //#region 用户
  getUser(userKey: string): Observable<UserEntity | undefined> {
    let user = this.users.get(userKey);
    return of(user);
  }
  saveUser(user: UserEntity): Observable<boolean> {
    this.users.set(user.userKey, user);
    return of(true);
  }
  //#endregion

  //#region 会话
  querySession(userKey: string): Observable<SessionEntity[]> {
    let sessions = this.userSessions.get(userKey);
    sessions = sessions ?? [];
    return of(sessions);
  }
  getSession(
    userKey: string,
    sessionKey: string
  ): Observable<SessionEntity | undefined> {
    let sessions = this.userSessions.get(userKey);
    sessions = sessions ?? [];
    let finded = sessions.find((e) => e.sessionKey == sessionKey);
    return of(finded);
  }
  saveSession(session: SessionEntity): Observable<boolean> {
    let sessions = this.userSessions.get(session.userKey);
    sessions = sessions ?? [];

    var index = sessions.findIndex((s1) => s1.sessionKey == session.sessionKey);
    if (index != -1) {
      sessions.splice(index, 1);
    }
    sessions.push(session);
    this.sortSessions(sessions);
    this.userSessions.set(session.userKey, [...sessions]);
    return of(true);
  }
  deleteSession(userKey: string, sessionKey: string): Observable<boolean> {
    let sessions = this.userSessions.get(userKey);
    sessions = sessions ?? [];
    var index = sessions.findIndex((s) => s.sessionKey == sessionKey);
    if (index != -1) {
      sessions.splice(index, 1);
      this.userMessages.delete(userKey + sessionKey);
      return of(true);
    }
    return of(false);
  }
  emptySession(userKey: string, sessionKey: string): Observable<boolean> {
    let sessions = this.userSessions.get(userKey);
    sessions = sessions ?? [];
    var index = sessions.findIndex((s) => s.sessionKey == sessionKey);
    if (index == -1) {
      return of(false);
    }
    sessions[index].lastMessageKey = "";
    sessions[index].lastMessageText = "[空空如也]";
    sessions[index].unreadMessageCount = 0;
    this.userMessages.delete(userKey + sessionKey);
    return of(true);
  }

  markSessionRead(userKey: string, sessionKey: string): Observable<boolean> {
    let sessions = this.userSessions.get(userKey);
    sessions = sessions ?? [];
    var index = sessions.findIndex((s) => s.sessionKey == sessionKey);
    if (index == -1) {
      return of(false);
    }
    sessions[index].unreadMessageCount = 0;
    let messages = this.userMessages.get(userKey + sessionKey);
    messages = messages ?? [];
    let realMsges = messages.filter((m) => m.hasRead == 0 && m.hasRevoked == 0);
    realMsges.forEach((rm) => {
      rm.hasRead = 1;
    });
    return of(true);
  }
  //#endregion

  //#region 消息
  queryMessage(
    userKey: string,
    sessionKey: string,
    maxRecord: number,
    sentTime: number | undefined
  ): Observable<MessageEntity[]> {
    let messages = this.userMessages.get(userKey + sessionKey);
    messages = messages ?? [];
    if (sentTime) {
      let realMsges = messages.filter((m) => m.sentTime < sentTime);
      return of(this.takeLastItems(realMsges, maxRecord));
    } else {
      return of(this.takeLastItems(messages, maxRecord));
    }
  }
  querySessionUnreadMessage(
    userKey: string,
    sessionKey: string
  ): Observable<MessageEntity[]> {
    let messages = this.userMessages.get(userKey + sessionKey);
    messages = messages ?? [];
    let realMsges = messages.filter((m) => m.hasRead == 0 && m.hasRevoked == 0);
    return of(realMsges);
  }
  getMessage(
    userKey: string,
    sessionKey: string,
    messageKey: string
  ): Observable<MessageEntity | undefined> {
    let messages = this.userMessages.get(userKey + sessionKey);
    messages = messages ?? [];
    let finded = messages.find((d) => d.messageKey == messageKey);
    if (finded) {
      return of({ ...finded });
    } else {
      return of(undefined);
    }
  }

  saveMessage(message: MessageEntity): Observable<boolean> {
    let messages = this.userMessages.get(message.userKey + message.sessionKey);
    messages = messages ?? [];

    var index = messages.findIndex((s) => s.messageKey == message.messageKey);
    if (index > -1) {
      messages.splice(index, 1);
    }
    messages.push(message);
    this.sortMessages(messages);
    this.userMessages.set(message.userKey + message.sessionKey, messages);
    return of(true);
  }
  //#endregion

  //#region 汇总查询
  getUnreadMessageCount(userKey: string): Observable<number> {
    let sessions = this.userSessions.get(userKey);
    sessions = sessions ?? [];

    let count = 0;
    sessions.map((s) => {
      count = count + s.unreadMessageCount;
    });
    return of(count);
  }
  //#endregion
}
