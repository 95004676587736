<script setup lang="ts">
import {
	onLaunch,
	onShow,
	onHide,
	onPageNotFound
} from "@dcloudio/uni-app";
import Container from "./services/ioc/Container";
import { NavigationServiceToken } from "./services/navigation/NavigationService";
onLaunch(() => {
	console.log("App Launch");
});
onShow(() => {
	console.log("App Show");
});
onHide(() => {
	console.log("App Hide");
});
onPageNotFound((options: any) => {
	const navigateService = Container.get(NavigationServiceToken);
	let mapResult = navigateService.mapLink(options.path);
	if (mapResult.mapped) {
		uni.navigateTo({ url: mapResult.mappedUrl })
	}
});
</script> 

<style lang="scss">
@import "@/uni_modules/za-ui/styles/main.scss";
</style>
