import { BehaviorSubject } from "rxjs";
import { Inject } from "../ioc/Inject";
import { Service } from "../ioc/Service";
import { Token } from "../ioc/Token";

import { LogServiceToken, type LogService } from "../logging/LogService";
import { Account, emptyAccount } from "./Account";

export const AccountServiceToken = new Token<AccountService>("AccountService");

@Service(AccountServiceToken)
export class AccountService {
  @Inject(LogServiceToken)
  logService!: LogService;

  private onAccountChanged$: BehaviorSubject<Account> =
    new BehaviorSubject<Account>(emptyAccount);

  public get onAccountChanged(): BehaviorSubject<Account> {
    return this.onAccountChanged$;
  }

  public get currentAccount() {
    return this.onAccountChanged$.getValue();
  }

  public set currentAccount(account: Account) {
    const oldAccount = this.onAccountChanged$.getValue();
    const newAccount = account ? account : emptyAccount;
    this.onAccountChanged$.next(newAccount);

    this.logService.debug(
      `账号变动：${JSON.stringify(oldAccount)} => ${JSON.stringify(newAccount)}`
    );
  }
}
