/**
 * 账户
 */
export class Account {
  private userKey: string = "";
  private authorities: string[] = [];
  private contexts: { [key: string]: any } = [];

  constructor(userKey: string) {
    this.userKey = userKey;
  }

  public get hasLogin() {
    return (
      this.userKey !== undefined && this.userKey !== null && this.userKey !== ""
    );
  }

  public getUserKey() {
    return this.userKey;
  }

  public getContext(key: string) {
    return this.contexts[key];
  }

  public setContext(key: string, val: any) {
    this.contexts[key] = val;
  }

  public mergeAuthorities(authorities: string[]) {
    if (authorities && authorities.length > 0) {
      this.authorities = this.authorities || [];
      authorities.forEach((authority) => {
        if (!this.authorities.includes(authority)) {
          this.authorities.push(authority);
        }
      });
    }
  }

  private innerHasAuthority(authority: string): boolean {
    if (this.userKey) {
      if (!authority) {
        return true;
      }
      return this.authorities.includes(authority);
    }
    return false;
  }

  public hasAuthority(authority: string): boolean {
    return this.innerHasAuthority(authority);
  }

  public hasAnyAuthority(authorities: string[] = []): boolean {
    if (this.userKey) {
      if (authorities == null || authorities.length == 0) {
        return true;
      }

      for (const authority in authorities) {
        if (this.innerHasAuthority(authority)) {
          return true;
        }
      }
    }
    return false;
  }

  public hasAllAuthorities(authorities: string[] = []): boolean {
    if (this.userKey) {
      if (authorities == null || authorities.length == 0) {
        return true;
      }

      for (const authority in authorities) {
        if (!this.innerHasAuthority(authority)) {
          return false;
        }
      }

      return true;
    }
    return false;
  }
}

/**
 * 空账户
 */
export const emptyAccount = new Account("");
