import { Observable } from "rxjs";
import { Interceptor } from "../../http/Interceptor";
import { InterceptorChain } from "../../http/InterceptorChain";
import { GetNextInterceptorDelegator } from "../../http/GetNextInterceptorDelegator";
import { InterceptorRequest } from "../../http/InterceptorRequest";
import { InterceptorResponse } from "../../http/InterceptorResponse";
import { settings } from "@/settings";

export class UniRequestInterceptor implements Interceptor {
  config = {
    timeout: 3000,
  };

  init(_: InterceptorChain): void {
    this.config = {
      ...this.config,
      ...settings.api,
    };
  }

  intercept(
    request: InterceptorRequest,
    _: GetNextInterceptorDelegator
  ): Observable<InterceptorResponse> {
    return new Observable(function (observer) {
      let uniRequest: UniApp.RequestOptions = {
        url: request.url,
        data: JSON.stringify(request.data),
        header: request.headers,
        method: request.method,
        timeout: request.timeout,
      };
      uni.request({
        ...uniRequest,
        /**
         * 成功返回的回调函数
         */
        success: (result: UniApp.RequestSuccessCallbackResult) => {
          let response = {
            data: result.data,
            statusCode: result.statusCode,
            headers: result.header,
          } as InterceptorResponse;
          observer.next(response);
          observer.complete();
        },
        /**
         * 失败的回调函数
         */
        fail: (result: UniApp.GeneralCallbackResult) => {
          observer.error(new Error(result.errMsg));
        },
        /**
         * 结束的回调函数（调用成功、失败都会执行）
         */
        complete: (result: UniApp.GeneralCallbackResult) => {},
      });
    });
  }
}
