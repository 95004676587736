import { Inject } from "@/services/ioc/Inject";
import { Service } from "@/services/ioc/Service";
import { LogService, LogServiceToken } from "@/services/logging/LogService";
import {
  StoreServiceToken,
  type StoreService,
} from "@/services/storage/StoreService";
import { fromUniApi } from "@/services/tools/fromUniApi";
import { settings } from "@/settings";
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  timeout,
} from "rxjs";
import {
  DeviceService,
  NetworkStatus,
  DeviceLocation,
  ScanResult,
  DeviceServiceToken,
} from "../DeviceService";

@Service(DeviceServiceToken)
export class UniDeviceService implements DeviceService {
  @Inject(StoreServiceToken)
  storeService!: StoreService;
  @Inject(LogServiceToken)
  logService!: LogService;

  config: any = {
    device_id_key: "__device_id",
  };

  constructor() {
    this.config = {
      ...this.config,
      ...settings.device,
    };
  }
  start(): Observable<boolean> {
    return of(true);
  }

  init(): void {
    this.initNetwork();
  }

  initNetwork() {
    let invokeEvent = (result: UniApp.OnNetworkStatusChangeSuccess) => {
      let status = {
        isConnected: result.isConnected,
        networkType: result.networkType,
      } as NetworkStatus;
      this.onNetworkStatusChanged$.next(status);
    };

    uni.getNetworkType({
      success: (result) => {
        invokeEvent({
          isConnected: result.networkType != "none",
          networkType: result.networkType,
        });
      },
    });
    uni.onNetworkStatusChange((result) => {
      invokeEvent(result);
    });
  }

  getDeviceId(): string {
    let deviceId = this.storeService.get(this.config.device_id_key);
    if (!deviceId) {
      deviceId = uni.getSystemInfoSync().deviceId;
      this.storeService.set(this.config.device_id_key, deviceId);
    }
    return deviceId;
  }

  private onNetworkStatusChanged$: BehaviorSubject<NetworkStatus> =
    new BehaviorSubject({
      isConnected: false,
      networkType: "unknow",
    } as NetworkStatus);

  get onNetworkStatusChanged() {
    return this.onNetworkStatusChanged$;
  }

  getLocation(): Observable<DeviceLocation> {
    return fromUniApi(uni.getLocation, {
      type: "gcj02",
      geocode: true,
    }).pipe(
      timeout(3000),
      catchError((e) => of(null)),
      map((r: any) => {
        let location = new DeviceLocation(false);
        if (r) {
          location.hasLocation = true;

          location.longitude = r.longitude;
          location.latitude = r.latitude;
          let cityCode = "";
          if (r.address && r.address.province && r.address.city) {
            cityCode =
              r.address.province.substring(0, 2) +
              r.address.city.substring(0, 2);
          }
          location.cityCode = cityCode;
          location.province = r.address?.province;
          location.city = r.address?.city;
          location.district = r.address?.district;
          let poi = "";

          if (r.address && r.address.poiName) {
            poi = r.address.poiName;
          } else if (r.address && r.address.street && r.address.streetNum) {
            poi = `${r.address.street}${r.address.streetNum}`;
          } else {
            poi = r.address?.poi;
          }
          location.poi = poi;
        }
        return location;
      })
    );
  }

  scanCode(option?: any): Observable<ScanResult> {
    return fromUniApi(uni.scanCode, option);
  }
}
