/**
 * 断言工具
 */
const AssertTool = {
  /**
   * 断言为真
   * @param expresson 表达式
   * @param message 描述
   */
  isTrue: (expresson: boolean, message: string) => {
    if (!expresson) {
      throw new Error(message);
    }
  },

  /**
   * 断言对象不为空
   * @param obj 对象
   * @param message 描述
   */
  notNull: (obj: any, message: string) => {
    if (obj === null || obj === undefined) {
      throw new Error(message);
    }
  },

  /**
   * 断言数组不能为空
   * @param arrays
   * @param message
   */
  notEmpty: (arrays: any[], message: string) => {
    if (!arrays || arrays.length == 0) {
      throw new Error(message);
    }
  },

  /**
   * 断言字符串不为空
   * @param str
   * @param message
   */
  hasLength: (str: string, message: string) => {
    if (!str || str.length == 0) {
      throw new Error(message);
    }
  },
};

export default AssertTool;
