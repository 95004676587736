import { ContainerMeta } from "./ContainerMeta";
import { ServiceMeta } from "./ServiceMeta";
import { Token } from "./Token";

export function Service(token: Token<any>) {
  return function (target: Function) {
    let serviceMeta = new ServiceMeta();
    serviceMeta.target = target;
    ContainerMeta.registService(token, serviceMeta);
  };
}
