export default {
  onBackPress() {
    let currentPage = this as any;
    let registedModals = currentPage.$ZaModals as any[];
    if (registedModals && registedModals.length > 0) {
      for (let i = registedModals.length - 1; i > -1; i--) {
        if (registedModals[i].isShowing()) {
          registedModals[i].hideDialog();
          return true;
        }
      }
    }
  },
};
