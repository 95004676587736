import AssertTool from "../tools/AssertTool";
import { InjectMeta } from "./InjectMeta";
import { ServiceMeta } from "./ServiceMeta";
import { Token } from "./Token";

export class ContainerMeta {
  private static serviceMetas = new Map<Token<any>, ServiceMeta>();
  private static serviceInjectMetas = new Map<any, InjectMeta[]>();
  public static registService(token: Token<any>, serviceMeta: ServiceMeta) {
    AssertTool.notNull(token, "令牌不能为空！");
    AssertTool.notNull(serviceMeta, "服务元数据不能为空！");
    this.serviceMetas.set(token, serviceMeta);
  }
  public static registInject(service: any, injectMeta: InjectMeta) {
    AssertTool.notNull(service, "服务不能为空！");
    AssertTool.notNull(injectMeta, "服务注入元数据不能为空！");
    let existedInjectMetas = this.serviceInjectMetas.get(service) || [];
    existedInjectMetas.push(injectMeta);
    this.serviceInjectMetas.set(service, existedInjectMetas);
  }
  public static getServiceMeta(token: Token<any>): ServiceMeta | undefined {
    return this.serviceMetas.get(token);
  }
  public static getServiceInjectMeta(service: any): InjectMeta[] | undefined {
    return this.serviceInjectMetas.get(service);
  }
}
