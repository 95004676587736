import { Service } from "@/services/ioc/Service";
import {
  NotificationService,
  NotificationServiceToken,
} from "../NotificationService";

@Service(NotificationServiceToken)
export class UniNotificationService implements NotificationService {
  message(message: string): void {
    uni.showToast({
      title: message,
      icon: "none",
    });
  }
  success(message: string): void {
    uni.showToast({
      title: message,
      icon: "none",
    });
  }
  warn(message: string): void {
    uni.showToast({
      title: message,
      icon: "none",
      duration: 3000,
    });
  }
  error(message: string): void {
    uni.showToast({
      title: message,
      icon: "none",
      duration: 3000,
    });
  }
}
