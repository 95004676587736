import { ChatDataAccessToken } from "@/packages/chat/services/chat/ChatDataAccess";
import { UniChatDataAccess } from "@/packages/chat/services/chat/impl/uni/UniChatDataAccess";

import { App } from "vue";
import { ApiServiceToken } from "../api/ApiService";
import { UniApiService } from "../api/impl/UniApiService";

// #ifdef APP-PLUS
import { DatabaseServiceToken } from "../database/DatabaseService";
import { UniDatabaseService } from "../database/impl/UniDatabaseService";
// #endif

// #ifndef APP-PLUS
import { H5ChatDataAccess } from "@/packages/chat/services/chat/impl/h5/H5ChatDataAccess";
// #endif

import { DeviceServiceToken } from "../device/DeviceService";
import { UniDeviceService } from "../device/impl/UniDeviceService";
import { UniLaunchService } from "../launch/impl/UniLaunchService";
import { LaunchServiceToken } from "../launch/LaunchService";
import { ConsoleLogService } from "../logging/impl/ConsoleLogService";
import { LogServiceToken } from "../logging/LogService";
import { UniNavigationService } from "../navigation/impl/UniNavitationService";
import { NavigationServiceToken } from "../navigation/NavigationService";
import { UniNotificationService } from "../notification/impl/UniNotificationService";
import { NotificationServiceToken } from "../notification/NotificationService";
import { UniStompService } from "../stomp/impl/UniStompService";
import { StompServiceToken } from "../stomp/StompService";
import { UniStoreService } from "../storage/impl/UniStoreService";
import { StoreServiceToken } from "../storage/StoreService";
import Container from "./Container";
import { CallAppService, CallAppServiceToken } from "../app/CallAppService";

export default {
  install(app: App) {
    app.config.globalProperties.$container = Container;
    Container.bind(LogServiceToken, ConsoleLogService);
    Container.bind(ApiServiceToken, UniApiService);
    // #ifdef APP-PLUS
    Container.bind(DatabaseServiceToken, UniDatabaseService);
    Container.bind(ChatDataAccessToken, UniChatDataAccess);
    // #endif
    // #ifndef APP-PLUS
    Container.bind(ChatDataAccessToken, H5ChatDataAccess);
    // #endif
    Container.bind(StompServiceToken, UniStompService);
    Container.bind(NavigationServiceToken, UniNavigationService);
    Container.bind(DeviceServiceToken, UniDeviceService);
    Container.bind(LaunchServiceToken, UniLaunchService);
    Container.bind(StoreServiceToken, UniStoreService);
    Container.bind(NotificationServiceToken, UniNotificationService);
    Container.bind(CallAppServiceToken, CallAppService);

    let launchService = Container.get(LaunchServiceToken);
    let logService = Container.get(LogServiceToken);
    let notificationService = Container.get(NotificationServiceToken);

    launchService.start(app).subscribe({
      next: () => {
        logService.debug("应用启动成功！");
      },
      error: (e) => {
        logService.debug("应用启动失败：" + JSON.stringify(e));
        notificationService.error("应用启动失败，请核查网络环境后再试！");
      },
    });
  },
};
