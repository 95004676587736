import { Observable } from "rxjs";

export class Locker {
  private observers = [] as Observable<boolean>[];
  private isLocked: boolean = false;

  private lockInternal(observer: any) {
    if (this.isLocked) {
      this.observers.push(observer);
    } else {
      this.isLocked = true;
      observer.next(true);
      observer.complete();
    }
  }

  public lock(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.lockInternal(observer);
    });
  }

  private runPending() {
    if (this.observers.length > 0) {
      let observer = this.observers.shift();
      this.lockInternal(observer);
    }
  }

  public unlock() {
    if (this.isLocked) {
      this.isLocked = false;
      setTimeout(() => this.runPending());
    }
  }
}
