import { Observable } from "rxjs";

export const fromUniApi = function (
  fun: Function,
  request: any
): Observable<any> {
  return new Observable(function (observer) {
    fun({
      ...request,
      success: (result: any) => {
        observer.next(result);
      },
      fail: (result: any) => {
        observer.error(new Error(`errMsg:${result.errMsg}`));
      },
      complete: (result: any) => {
        observer.complete();
      },
    });
  });
};
