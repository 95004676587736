export const settings = {
  log: {
    level: "debug",
  },

  navigation: {
    schemas: ["https://m.onrecord.cn/", "onrecord://"],
    maps: [
    ],
  },

  device: {
    device_id_key: "__device_id",
  },

  token: {
    token_key: "__token",
  },

  api: {
    url: "https://app-api.onrecord.cn",
    timeout: 30000,
  },

  stomp: {
    server: "wss://app-api.onrecord.cn/user/chat/ws",
  },

  database: {
    name: "yyp",
    path: "_doc/yyp.db",
    sqls: [
      `create table if not exists "user" (
                "userKey" TEXT(32) NOT NULL,
                "syncTime" INTEGER,
                PRIMARY KEY ("userKey")
              )`,
      `create table if not exists "session" (
                "userKey" TEXT(32) NOT NULL,
                "sessionKey" TEXT(32) NOT NULL,
                "sessionTypeId" INTEGER,
                "starterKey" TEXT(32),
                "name" TEXT(64),
                "avatarPath" TEXT(128),
                "fixTop" INTEGER,
                "unreadMessageCount" INTEGER,
                "lastMessageKey" TEXT(64),
                "lastMessageText" TEXT,
                "lastMessageSentTime" INTEGER,
                "activeTime" INTEGER,
                PRIMARY KEY ("userKey", "sessionKey")
              )`,
      `create index if not exists "idx_session" on "session" ("userKey", "fixTop", "activeTime")`,
      `create table if not exists "message" (
                "userKey" TEXT(32) NOT NULL,
                "sessionKey" TEXT(32) NOT NULL,
                "messageKey" TEXT(32) NOT NULL,
                "senderKey" TEXT(32) NOT NULL,
                "messageTypeId" INTEGER NOT NULL,
                "body" TEXT,
                "sentTime" INTEGER,
                "blockedReason" TEXT(64),
                "userCount" INTEGER,
                "receiveCount" INTEGER,
                "readCount" INTEGER,
                "revokeCount" INTEGER,
                "hasRead" INTEGER,
                "hasRevoked" INTEGER,
                "hasDeleted" INTEGER,
                "activeTime" INTEGER,
                "status" INTEGER,
                PRIMARY KEY ("userKey", "sessionKey", "messageKey")
              )`,
      `create index if not exists "idx_message" on "message" ("userKey", "sessionKey", "sentTime")`,
    ],
  },

  callApp: {},
};
