import { settings } from "@/settings";
import { BehaviorSubject, catchError, map, Observable, of, tap } from "rxjs";
import {
  type DeviceService,
  DeviceServiceToken,
} from "../device/DeviceService";
import { type LogService, LogServiceToken } from "../logging/LogService";
import { StoreServiceToken, type StoreService } from "../storage/StoreService";
import { Account, emptyAccount } from "./Account";
import { AccountService, AccountServiceToken } from "./AccountService";
import { GetUserRequest } from "./contracts/GetUserRequest";
import { GetUserResponse } from "./contracts/GetUserResponse";
import { Token } from "./Token";
import { Token as TypediToken } from "../ioc/Token";
import { type ApiService, ApiServiceToken } from "../api/ApiService";
import { RefreshOauthTokenRequest } from "./contracts/RefreshOauthTokenRequest";
import { RefreshOauthTokenResponse } from "./contracts/RefreshOauthTokenResponse";
import { Inject } from "../ioc/Inject";
import { ServiceStart } from "../launch/ServiceStart";
import { App } from "vue";
import { Service } from "../ioc/Service";
import {
  NavigationService,
  NavigationServiceToken,
} from "../navigation/NavigationService";

export const TokenServiceToken = new TypediToken<TokenService>("TokenService");

@Service(TokenServiceToken)
export class TokenService implements ServiceStart {
  //#region 私有字段
  @Inject(LogServiceToken)
  logService!: LogService;
  @Inject(DeviceServiceToken)
  deviceService!: DeviceService;
  @Inject(AccountServiceToken)
  accountService!: AccountService;
  @Inject(StoreServiceToken)
  storeService!: StoreService;
  @Inject(ApiServiceToken)
  apiService!: ApiService;
  @Inject(NavigationServiceToken)
  navigationService!: NavigationService;

  config: any = {
    token_key: "__token",
    token_refresh_days: 5,
  };

  constructor() {
    this.config = {
      ...this.config,
      ...settings.token,
    };
  }
  //#endregion

  //#region 令牌变更事件
  private onTokenChanged$: BehaviorSubject<Token> = new BehaviorSubject<Token>(
    new Token("")
  );

  public get onTokenChanged(): Observable<Token> {
    return this.onTokenChanged$;
  }

  public get currentToken() {
    return this.onTokenChanged$.getValue();
  }
  //#endregion

  public start(app: App): Observable<boolean> {
    return of(true);
    // return this.autoLogin().pipe(
    //   map((r) => {
    //     this.logService.debug("访问令牌服务初始化完成");
    //     return true;
    //   })
    // );
  }

  private loadAccount(): Observable<Account> {
    let request = new GetUserRequest();
    return this.apiService
      .callServiceAndShowError<GetUserResponse>(request)
      .pipe(
        map((r) => {
          let account = new Account(r.user!.userKey);
          this.accountService.currentAccount = account;
          return account;
        })
      );
  }

  public login(rawToken: string) {
    this.storeService.set(this.config.token_key, rawToken);
    this.onTokenChanged$.next(new Token(rawToken));
    return this.loadAccount().pipe(
      tap((r) => {
        this.logService.debug(`用户'${r.getUserKey()}'登录成功`);
      })
    );
  }

  public autoLogin(): Observable<Account | null> {
    let rawToken = this.storeService.get(this.config.token_key);
    if (rawToken) {
      this.onTokenChanged$.next(new Token(rawToken));
      return this.loadAccount().pipe(
        tap((r) => {
          this.logService.debug(`用户'${r.getUserKey()}'自动登录成功`);
        }),
        catchError((e) => {
          this.navigationService.goLogin();
          return of(null);
        })
      );
    } else {
      return of(null);
    }
  }

  public logout() {
    this.storeService.remove(this.config.token_key);
    this.onTokenChanged$.next(new Token(""));
    this.accountService.currentAccount = emptyAccount;
  }

  public refreshToken(): Observable<boolean> {
    let token = this.onTokenChanged$.value;
    if (token.hasToken()) {
      let request = new RefreshOauthTokenRequest();
      request.oauthToken = token.getRawToken();
      request.deviceFeature = this.deviceService.getDeviceId();
      return this.apiService
        .callServiceAndShowError<RefreshOauthTokenResponse>(request)
        .pipe(
          map((r) => {
            this.storeService.set(this.config.token_key, r.oauthToken);
            this.onTokenChanged$.next(new Token(r.oauthToken));
            this.logService.debug("访问令牌已刷新");
            return true;
          }),
          catchError((e) => {
            this.navigationService.goLogin();
            return of(false);
          })
        );
    } else {
      return of(false);
    }
  }
}
