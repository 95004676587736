import { Token } from "../ioc/Token";

/**
 * 消息服务令牌
 */
export const NotificationServiceToken = new Token<NotificationService>(
  "NotificationService"
);

/**
 * 消息服务
 */
export interface NotificationService {
  /**
   * 显示交互消息
   * @param message 消息
   */
  message(message: string): void;
  /**
   * 显示成功消息s
   * @param message 消息
   */
  success(message: string): void;
  /**
   * 显示警告消息
   * @param message 消息
   */
  warn(message: string): void;
  /**
   * 显示异常消息
   * @param message 消息
   */
  error(message: string): void;
}
