import { ContainerMeta } from "./ContainerMeta";
import { InjectMeta } from "./InjectMeta";
import { Token } from "./Token";

export function Inject(token: Token<any>) {
  return function (target: any, key: string) {
    let injectMeta = new InjectMeta();
    injectMeta.property = key;
    injectMeta.token = token;
    ContainerMeta.registInject(target.constructor, injectMeta);
  };
}
