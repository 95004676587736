import { Service } from "../ioc/Service";
import { Token } from "../ioc/Token";
import CallApp from "callapp-lib";

export const CallAppServiceToken = new Token<CallAppService>("CallAppService");

@Service(CallAppServiceToken)
export class CallAppService {
  private callLib: any;
  constructor() {
    const option = {
      scheme: {
        protocol: "onrecord",
      },
      intent: {
        package: "cn.onrecord.app",
        scheme: "onrecord",
      },
      universal: {
        host: "m.onrecord.cn",
      },
      appstore: "https://apps.apple.com/cn/app/id1591868117",
      // yingyongbao: "//a.app.qq.com/o/simple.jsp?pkgname=cn.onrecord.app",
      fallback: "https://www.onrecord.cn",
      timeout: 5000,
    };
    this.callLib = new CallApp(option);
  }

  callApp(path: string) {
    this.callLib.open({
      path,
    });
  }
}
