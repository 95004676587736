import { settings } from "@/settings";
import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { Interceptor } from "../http/Interceptor";
import { InterceptorChain } from "../http/InterceptorChain";
import { InterceptorRequest } from "../http/InterceptorRequest";
import { TokenInterceptor } from "./interceptor/TokenInterceptor";
import { LogInterceptor } from "./interceptor/LogInterceptor";
import { ApiService, ApiServiceToken } from "../ApiService";
import { UniRequestInterceptor } from "./interceptor/UniRequestInterceptor";
import { ApiResponse } from "../ApiResponse";
import {
  NotificationService,
  NotificationServiceToken,
} from "@/services/notification/NotificationService";
import { ApiError } from "../ApiError";
import Container from "@/services/ioc/Container";
import { LogService, LogServiceToken } from "@/services/logging/LogService";
import { Service } from "@/services/ioc/Service";
import { Inject } from "@/services/ioc/Inject";

@Service(ApiServiceToken)
export class UniApiService implements ApiService {
  @Inject(NotificationServiceToken)
  notificationService!: NotificationService;
  @Inject(LogServiceToken)
  logService!: LogService;

  config = {
    timeout: 3000,
  };

  private http!: InterceptorChain;

  constructor() {
    this.config = {
      ...this.config,
      ...settings.api,
    };
  }

  get priority(): number {
    return 10;
  }

  init(app?: any): Observable<boolean> {
    let interceptors: Interceptor[] = [];
    const logInterceptor = new LogInterceptor();
    const tokenInterceptor = new TokenInterceptor();
    const uniRequestInterceptor = new UniRequestInterceptor();

    interceptors.push(logInterceptor);
    interceptors.push(tokenInterceptor);
    interceptors.push(uniRequestInterceptor);
    this.http = new InterceptorChain(interceptors);

    return of(true).pipe(
      tap((r) => {
        let logService = Container.get(LogServiceToken);
        logService.info("API服务初始化完成");
      })
    );
  }

  private callServiceInternal<T extends ApiResponse>(
    url: string,
    req: any,
    method?:
      | "OPTIONS"
      | "GET"
      | "HEAD"
      | "POST"
      | "PUT"
      | "DELETE"
      | "TRACE"
      | "CONNECT",
    timeout?: number
  ): Observable<T> {
    const headers: { [key: string]: any } = {};
    headers["Content-Type"] = "application/json";

    const request: InterceptorRequest = {
      url,
      headers,
      data: req,
      method: method,
      timeout: timeout ? timeout : 3000,
    };

    return this.http.intercept(request).pipe(
      map((response) => {
        return response.data as T;
      })
    );
  }

  public callService<T extends ApiResponse>(
    request: any,
    options?: {
      timeout?: number;
      url?: string;
    }
  ): Observable<T> {
    options = {
      ...this.config,
      ...(options ?? {}),
    };
    const url = `${options.url}${request.$Meta.path}`;
    const method = request.$Meta.method;
    return this.callServiceInternal(url, request, method, options.timeout);
  }

  public callServiceAndShowError<T extends ApiResponse>(
    request: any,
    options?: {
      timeout?: number;
      url?: string;
    }
  ): Observable<T> {
    return this.callService<T>(request, options).pipe(
      map((r) => {
        if (r.resultCode !== 0) {
          this.notificationService.error(r.resultMessage);
          throw new ApiError(r.resultMessage, request, r);
        }
        return r;
      })
    );
  }
}
