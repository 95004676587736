import { Subject } from "rxjs";
import Stomp from "stompjs";
import { ServiceInit } from "../ioc/ServiceInit";
import { Token } from "../ioc/Token";

export const StompServiceToken = new Token<StompService>("StompService");
/**
 * stomp 服务
 */
export interface StompService extends ServiceInit {
  /**
   * 连接事件
   */
  onConnected(): Subject<boolean>;

  /**
   * 失连事件
   */
  onDisConnected(): Subject<boolean>;

  /**
   * 异常事件
   */
  onError(): Subject<any>;

  /**
   * 连接
   */
  connect(): void;

  /**
   * 断开
   */
  disconnect(): void;

  /**
   * 消息到达事件
   * @param topic 主题
   */
  onMessageReceived(topic: string): Subject<Stomp.Message>;

  /**
   * 发送消息
   * @param topic 主题
   * @param body 消息
   */
  sendMessage(topic: string, body?: string): void;
}
