import { BehaviorSubject, concat, map, Observable, of, takeLast } from "rxjs";
import { App } from "vue";
import { Token } from "../ioc/Token";
import { ServiceStart } from "./ServiceStart";

export const LaunchServiceToken = new Token<LaunchService>("LaunchService");

export abstract class LaunchService {
  private serviceStarts: ServiceStart[] = [];

  protected registerService(serviceStart: ServiceStart) {
    this.serviceStarts.push(serviceStart);
  }

  public abstract prepare(): void;

  private onLaunched$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public get onLaunched(): BehaviorSubject<boolean> {
    return this.onLaunched$;
  }

  public start(app: App): Observable<boolean> {
    this.prepare();
    if (this.serviceStarts.length == 0) {
      return of(true);
    }

    let services: Observable<boolean>[] = [];
    this.serviceStarts.forEach((s) => {
      services.push(s.start(app));
    });
    return concat(...services).pipe(
      takeLast(1),
      map((r) => {
        this.onLaunched$.next(true);
        return true;
      })
    );
  }
}
