import jwtDecode from "jwt-decode";

/**
 * 令牌
 */
export class Token {
  private rawToken: string;
  private parsedToken: any;

  constructor(rawToken: string) {
    this.rawToken = rawToken;
    this.parsedToken = null;
  }

  getRawToken() {
    return this.rawToken;
  }

  hasToken() {
    return this.rawToken && this.rawToken.length > 0;
  }

  getParsedToken() {
    if (!this.parsedToken && this.hasToken()) {
      this.parsedToken = jwtDecode(this.rawToken);
    }
    return this.parsedToken;
  }
}
